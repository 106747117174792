.c-pagefooter {
  border-left: 4px solid $color-white--dark;
  border-right: 4px solid $color-white--dark;
  margin: 2rem 0 0;
  padding: 1rem 0;
  background-color: $color-white--medium;
  position: relative;
  legend {
    position: absolute;
    background-color: $color-black--light;
    color: $color-white--light;
    padding: 0 .5rem;
    right: 0;
    top: -.75rem;
  }

  &__category {
    margin: .5rem;
    .c-icon {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: bottom;
      fill: rgba(black, .5);
      margin-right: .5rem;
    }
  }
  .c-tags {
    margin-left: 1.9rem;
    margin-top: .75rem;
    margin-bottom: .2rem;
    padding-left: .8rem;
  }
  .c-tags:before {
    color: rgba(black, .5);
    font-size: 1.25rem;
  }

  &__controls {
    display: flex;
    padding: 1rem .5rem .75rem;
    justify-content: space-between;
    align-items: center;
    .label {
      text-transform: uppercase;
      color: rgba(black, 0.65);
      font-size: .85rem;
      display: flex;
      align-items: center;
    }
    .counter {
      font-family: monospace;
      font-size: 2rem;
      color: rgba(royalblue, 0.75);
      text-align: center;
      small {
        color: rgba(black, 0.65);
      }
    }
    .previous {
      .c-icon { margin-right: .25rem; }
      a { margin-left: .25rem; }
    }
    .next {
      text-align: right;
      .label { justify-content: flex-end; }
      .c-icon { margin-left: .25rem; }
      a { margin-right: .25rem; }
    }
    @media (max-width: $sm) {
      flex-direction: column;
      & > div {
        width: 100%;
        margin: .5rem;
      }
    }
  }
  .sources {
    padding: 0 .5rem 0 1rem;
    & ~ .sep {
      font-size: 1.5rem;
      color: $color-gray--light;
    }
    & + .wikiloc::before {
      content: url(../images/wikiloc.png);
      vertical-align: middle;
    }
    & + .wikiloc::before {
      content: url(../images/wikiloc.png);
      vertical-align: middle;
    }
  }
}

.c-map {
  // margin-bottom: 1rem;
  // summary {
  //   padding: .5rem 1rem;
  //   span {
  //     // background-color: $color-black--light;
  //     // color: $color-white--light;
  //     padding: 0 .5rem;
  //     display: inline-block;
  //   }
  // }
  position: relative;

  button.region {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: .1rem;
    z-index: 401;
    width: 30px;
    height: 30px;
    line-height: 28px;
    border: 2px solid #ccc;
    border-radius: 2px;
    background-color: #fff;
    margin: 0;
    transition: all .3s;
    &:hover { background-color: #f4f4f4; }
    &.active {
      background-color: rgba(royalblue, .95);
      color: white;
    }
  }
  .leaflet-editable {
    .leaflet-vertex-icon, .leaflet-overlay-pane {
      display: none;
    }
    &.editing {
      .leaflet-vertex-icon, .leaflet-overlay-pane {
        display: initial;
      }
    }
  }
  .leaflet-marker-icon {
    cursor: pointer;
    opacity: .85;
    transition: filter .3s, opacity .3s;
    &.destinations { filter: hue-rotate(270deg);  }
    &.sights { filter: hue-rotate(90deg); }
    &.arts { filter: hue-rotate(180deg); }
    &.info { filter: none; }
    &:hover, &:focus {
      opacity: 1;
    }
  }
  .c-tags {
    margin-top: .75rem;
    margin-left: auto;
  }
  .title, .c-tags a {
    color: initial;
  }
  .leaflet-popup-content {
    text-align: center;
    img {
      width: 100%;
      max-width: 320px;
      // height: 75px;
    }
    .title {
      font-size: 1.1rem;
    }
  }
  .leaflet-container {
    a { color: initial; }
    .leaflet-control-attribution a {
      // color: royalblue;
      color: rgba(darken(royalblue, 25%), .75);
    }
    // .leaflet-popup-close-button {
    //   font-size: 2rem;
    //   width: auto;
    //   height: auto;
    //   top: 6px;
    //   right: 9px
    // }
  }
  .leaflet-marker-icon.inactive {
    filter: grayscale(1);
  }
}

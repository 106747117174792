.link-preview {
  position: absolute;
  z-index: 9;
  background-color: $color-black--medium;
  color: white;
  // left: -50%;
  width: calc(100vw - 3rem);
  max-width: 480px;
  bottom: 100%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 black;
  padding: 1rem;
  opacity: 0;
  transition: opacity .25s;
  &.visible {
    opacity: 1;
  }
  header {
    display: flex;
    align-items: center;
    gap: .75rem;
  }
  img {
    width: 100px;
  }
  h3 {
    margin: .5rem .25rem;
    font-size: 1.5rem;
  }
  .c-tags {
    display: flex;
    gap: 4px;
  }
  .c-tags:before {
    content: '#';
    color: rgba(255,255,255,0.5);
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    left: -1.15rem;
    padding: 0.1rem;
    height: calc(100% - .4rem);
    display: flex;
    align-items: center;
  }
  .c-tag {
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 1.25;
    background-color: $color-black--dark;
  }
  p {
    font-size: .9rem;
    line-height: 1.25;
    margin: .5rem 0;
  }
}



.previewable {
  position: relative;
  z-index: 1100;
}
.s-info {
  .map {
    box-shadow: 0 0 1px 0 #aaa;
    margin-bottom: .5rem;
    path { transition: all .25s; }
    path[id^='text'] { pointer-events: none; }
  }

  .root {
    display: none;
    h3 {
      margin: .75rem 0 .25rem;
    }
    p {
      margin: .5rem 0;
      line-height: 1.2;
    }
    &.visible {
      display: initial;
    }
  }

  .root:not(.visible) + .details {
    ::marker { display: none; }
    ::-webkit-details-marker { display: none; }

    summary {
      font-size: 1.333rem;
      line-height: 1.5rem;
      font-weight: normal;
      pointer-events: none;
      margin: .75rem 0 .25rem;
    }
  }


  .details {
    margin: .5rem 0;
    cursor: pointer;
    ul {
      padding: 0 0 0 2rem;
    }
  }
}

.c-links {
  // background-color: $color-black--dark;
  display: flex;
  // max-width: $xl;
  // margin: auto;

  a {
    position: relative;
    display: block;
    color: white;
    border-bottom: 0;
    min-height: 50vh;
    width: 33.33%;
    opacity: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      transition: all $transition-normal;
    }
    &:hover {
      &::after {
        background: transparent;
      }
      img {
        opacity: 1;
      }
      strong, em {
        background: rgba(0, 0, 0, 0.8);
      }
    }
    picture {
      position: absolute;
      height: 100%;
      width: 100%;
    }
    img {
      transition: all $transition-normal;
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: .95;
    }
    span {
      position: absolute;
      bottom: 2rem;
      left: 1rem;
      max-width: calc(100% - 2rem);
      z-index: 1;
    }
    strong, em {
      padding: .25rem .5rem;
      display: block;
      transition: all $transition-normal;
    }
    strong {
      margin-bottom: 1px;
      font-size: 1.25rem;
      display: inline-block;
    }

  }

  @media (max-width: $md) {
    flex-direction: column;
    a { width: 100%; }
  }
}
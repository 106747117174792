.c-filters {
  details {
    display: inline-flex;
  }
  details[open] {
    display: block;
    summary {
      .c-icon { transform: rotate(90deg); }
    }
  }
  .c-icon {
    transition: all 0.3s;
  }
  summary {
    // position: absolute;
    padding: 0 1rem;
    // right: 0;
    // top: 0;
    height: 2.5rem;
    line-height: 2.5rem;
    color: rgba(darken(royalblue, 25%), .75);
    cursor: pointer;
    display: inline-flex;
    gap: .5rem;
    align-items: center;
    background-color: $color-white--medium;
    transition: background-color 0.3s;
    &:hover, &:focus {
      background-color: $color-white--dark;
    }
    &.active {
      background-color: rgba(royalblue, .95);
      color: white;
    }
  }

  ::marker { display: none; }
  ::-webkit-details-marker { display: none; }

  .range {
    margin: 1rem 1rem 3rem;
  }
}
// Page Footer
//
// Markup:
// <footer class="p-footer">
//   ...
// </footer>
//
// Styleguide Patterns.Footer

.p-footer {
  background-color: rgba(black, .85);
  // scroll-snap-align: end;
}

.p-footer__container {
  max-width: $lg;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  // & > div { flex-basis: 25%; }
  // & > div:first-child { flex-basis: 50%; }
}

.p-footer__links {
  display: flex;
  align-items: center;
  justify-content: center;
  .c-icon {
    margin: 0 .25rem;
    vertical-align: text-top;
  }
  a {
    display: flex;
    text-align: center;
    border-bottom: 1px dashed $color-gray--dark;
    color: $color-white--medium;
    padding: .5rem .5rem;
    margin: 0 1rem;
    font-size: .85rem;
    max-width: 90%;
    line-height: 1.5;
    &:hover, &:focus {
      color: $color-white--light;
      border-bottom-color: $color-white--light;
    }
  }
}

.p-footer__copyright {
  padding: 0 1rem;
  color: $color-gray--light;
  position: relative;
  font-size: .9rem;
  display: flex;
  align-items: center;
  order: -1;
  span {
    display: block;
    line-height: 1.25;
    padding: .5rem 0;
  }
  a {
    color: $color-white--medium;
    border-bottom-color: transparent;
    &:hover, &:focus {
      color: $color-white--light;
      border-bottom-color: $color-white--light;
     }
  }
}

.p-footer__back-to-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0;
    color: $color-gray--dark;
    &:hover, &:focus {
      color: $color-gray--light;
    }
    .c-icon {
      height: 2rem;
      width: 2rem;
      &.logo {
        height: 3.5rem;
        width: 7.875rem;
        margin: 0 .5rem 0 0;
      }
    }
  }
}

@media (max-width: $lg) {
  .p-footer__container {
    display: block;
  }
  .p-footer__copyright {
    position: absolute;
    left: .5rem;
    bottom: .75rem;
  }
}
@media (max-width: $md) {
  .p-footer__links {
    margin-top: 1rem;
    span { font-size: 0; }
  }
  .p-footer__copyright {
    margin-top: 1rem;
    position: relative;
    left: auto;
    bottom: auto;
    span {
      display: inline-block;
      margin: auto;
    }
  }
  .p-footer__back-to-top {
    justify-content: center;
  }
}

@charset "utf-8";


/**
 * Settings.
 *
 * Design tokens for UI.
 */
@import 'settings';

/**
 * Tools.
 *
 * Globally used functions, placeholders and mixins.
 */
@import 'tools';

/**
 * Vendors.
 *
 * 3<sup>rd</sup> party vendors including normalize/reset.
 */
@import 'normalize';
@import '../lib/hes-gallery.min.css';
@import '../lib/nouislider.min.css';

/**
 * Objects.
 *
 * Class-based selectors which define undecorated design patterns, e.g. layout.
 */
 @import 'container';

/**
 * Elements.
 *
 * Styling for bare HTML elements, e.g., H1, a, etc.
 */
@import 'elements';

/**
 * Components.
 *
 * UI components.
 */
@import 'components/icon';
@import 'components/button';
@import 'components/form';
@import 'components/tag';
@import 'components/slider';
@import 'components/list';
@import 'components/pagefooter';
@import 'components/map';
@import 'components/hero';
@import 'components/gallery';
@import 'components/links';
@import 'components/trail';
@import 'components/filters';
@import 'components/linkpreview';
// @import 'components/pagination';

/**
 * Patterns.
 *
 * This is collections of components.
 */
@import 'patterns/common';
@import 'patterns/header';
@import 'patterns/footer';
@import 'patterns/topbar';

/**
 * Scopes.
 *
 * Scoping in the page level but only used when it's absolutely necessary.
 */

@import 'scopes/home';
// @import 'scopes/index';
@import 'scopes/info';
@import 'scopes/bazaar';
@import 'scopes/404';

/**
 * Utilities.
 *
 * Ability to override anything.
 * It will most likely come with !important as we are sure to use them.
 */

@import 'utils';
// @import 'highlight';
// @import 'debug';

.c-gallery {
  display: flex;
  flex-wrap: wrap;
  picture {
    position: relative;
    display: flex;
    margin: .1rem;
    &:hover, &:focus { cursor: zoom-in; }
  }
  picture:after {
    content: attr(data-caption);
    position: absolute;
    bottom: .5rem;
    width: 100%;
    text-align: center;
    color: white;
    line-height: 1;
    padding: .5rem;
    box-sizing: border-box;
  }
  picture:before {
    content: '';
    transition: all .25s;
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 25%;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(black, 0) 0px, rgba(black, 0.75) 100%) repeat 0 0;
  }
  picture:hover:before {
    height: 100%;
  }
  @media (min-width: $sm) {
    picture {
      flex-basis: calc(100%/3 - .2rem);
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 240px;
      object-fit: cover;
    }

    &.is-big {
      picture { flex-basis: calc(25% - .2rem); }
      img { max-height: 180px; }
    }

    &.size-1 {
      picture { flex-basis: 100%; }
      img { max-height: 320px; }
    }
    &.size-2 {
      picture { flex-basis: calc(50% - .2rem); }
      img { max-height: 280px; }
    }
    &.size-4 {
      picture:last-child { flex-basis: 100%; }
    }
    &.size-5 {
      picture:last-child, picture:nth-last-child(2) {
        flex-basis: calc(50% - .2rem);
      }
    }
    &.size-7 {
      picture:last-child, picture:nth-last-child(2),
      picture:nth-last-child(3), picture:nth-last-child(4) {
        flex-basis: calc(25% - .2rem);
      }
    }
    &.size-8 {
      picture { flex-basis: calc(100%/3 - .2rem); }
      picture:nth-child(4), picture:nth-child(5) {
        flex-basis: calc(50% - .2rem);
      }
    }
    &.size-9 {
      picture:first-child, picture:nth-child(2), picture:nth-child(3) {
        flex-basis: calc(100%/3 - .2rem);
      }
      picture:last-child, picture:nth-last-child(2) {
        flex-basis: calc(50% - .2rem);
      }
    }
    &.size-10 {
      picture:nth-child(5), picture:nth-child(6) {
        flex-basis: calc(50% - .2rem);
      }
    }
    &.size-11 {
      picture:nth-child(6) {
        flex-basis: calc(50% - .2rem);
      }
    }
    // &.size-12 {
    //   picture:first-child, picture:nth-child(6),
    //   picture:nth-child(7), picture:last-child {
    //     flex-basis: calc(50% - .2rem);
    //   }
    // }
  }
}
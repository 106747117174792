.s-home {
  width: 100%;
  height: 100%;
  picture.background img {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    margin-top: -3.75rem;
    object-fit: cover;
    // object-position: top;
  }
  .leaflet-marker-icon {
    cursor: pointer;
    &.destination { filter: hue-rotate(270deg);  }
    &.sight { filter: hue-rotate(90deg); }
  }
}

.s-home__intro {
  display: flex;
  max-width: 80rem;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // max-height: 50rem;
  min-height: calc(100vh - 3.75rem);
  overflow: hidden;

  & > div {
    clip-path: polygon(1% 0%, 20% 1%, 44% 0%, 67% 2%, 88% 0%, 100% 2%, 99% 18%, 100% 38%, 98% 54%, 100% 72%, 98% 88%, 100% 100%, 79% 100%, 71% 99%, 64% 100%, 38% 100%, 32% 98%, 25% 100%, 2% 100%, 0% 91%, 1% 77%, 0% 62%, 1% 45%, 0% 28%, 2% 17%, 0% 9%);
    display: flex;
    border-bottom: 0;
    flex-direction: column;
    width: 100%;
    @media (min-width: $sm) {
      width: 50%;
    }
    @media (min-width: $lg) {
      width: 25%;
      min-height: 65vh;
    }
    // max-height: 20rem;
    // max-height: 100vh;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
    color: white;
    transition: all $transition-slow;
    text-align: center;
    backdrop-filter: sepia(1) grayscale(1) brightness(.5);
    -webkit-backdrop-filter: sepia(1) grayscale(1) brightness(.5);
    background: rgba(black, .1);
    &:hover {
      clip-path: polygon(0 0, 19% 1%, 35% 0, 46% 0, 55% 1%, 64% 0, 74% 1%, 92% 0, 100% 0, 99% 20%, 100% 30%, 99% 42%, 100% 55%, 99% 68%, 100% 78%, 99% 92%, 100% 100%, 91% 100%, 80% 99%, 68% 100%, 60% 99%, 53% 99%, 43% 100%, 35% 99%, 26% 99%, 19% 100%, 14% 97%, 7% 100%, 1% 99%, 0% 92%, 2% 84%, 0% 76%, 2% 71%, 0% 65%, 0% 57%, 0% 53%, 2% 44%, 0% 40%, 2% 32%, 0% 30%, 1% 22%, 0% 20%, 0% 11%);
      backdrop-filter: sepia(0) grayscale(0) brightness(1);
      -webkit-backdrop-filter: sepia(0) grayscale(0) brightness(1);
      .title { color: bisque; }
      .c-icon { fill: bisque; }
      .c-button { border-color: bisque; }
    }
    p {
      padding: 1rem;
      margin: 0 .5rem auto;
      max-width: 22rem;
      background-color: rgba(black, .5);
      @media (min-width: $lg) {
        min-height: 10rem;
      }
    }
    .title {
      margin: 1rem auto;
      padding: .5rem 1rem;
      display: inline-flex;
      font-size: 1.25rem;
      // flex-direction: column;
      align-items: center;
      text-transform: uppercase;
      color: rgba(white, .85);
      // color: bisque;
      background-color: rgba(black, .5);
      border-bottom: 0;
      transition: all $transition-fast;
      // width: 100%;
    }
    .c-icon {
      width: 1.25rem;
      height: 1.25rem;
      fill: rgba(white, .75);
      transition: all $transition-fast;
      margin-right: .5rem;
    }
    .c-button {
      margin-bottom: 2rem;
    }
    .c-button:hover, .c-button:focus {
      background-color: rgba(royalblue, .5);
      color: white;
    }
  }
}

@supports not ((-webkit-backdrop-filter: sepia(1)) or (backdrop-filter: sepia(1))) {
  .s-home__intro > div:nth-child(1):hover { background-color: rgba(limegreen, .1); }
  .s-home__intro > div:nth-child(2):hover { background-color: rgba(blueviolet, .1); }
  .s-home__intro > div:nth-child(3):hover { background-color: rgba(orangered, .1); }
  .s-home__intro > div:nth-child(4):hover { background-color: rgba(royalblue, .1); }
}

@media (max-width: $lg) {
  .s-home__intro > div {
    &:hover .c-button {
      background-color: rgba(royalblue, .9);
    }
    .c-button {
      background-color: rgba(royalblue, .5);
    }
    .c-button:hover, .c-button:focus {
      background-color: royalblue;
      color: white;
    }
  }
}

@media (max-width: $md) {
  .s-home__intro {
    // flex-direction: column;
    max-height: 100%;
    & > div {
      text-align: left;
      width: 90%;
      backdrop-filter: sepia(1) grayscale(1) brightness(.25);
      border-bottom: 1px solid $color-gray--dark;
      a.title {
        flex-direction: row;
        width: calc(100% - 2rem);
        padding-bottom: 0;
        .c-icon {
          width: 2rem;
          height: 2rem;
          order: -1;
          margin-right: 1rem;
        }
      }
      p {
        margin: 0;
        max-width: 100%;
      }
      .c-button {
        margin-left: 1rem;
        margin-right: auto;
        color: white;
        background-color: rgba(royalblue, .75);
      }
      &:nth-child(even) .c-button {
        margin-left: auto;
        margin-right: 1rem;
      }
    }
  }
}

.s-home__weather {
  scroll-snap-align: end;
}

.s-home__sliders, .s-home__links {
  scroll-snap-align: start;
}
.s-home__links.snap-end {
  scroll-snap-align: end;
}

.s-home__map {
  scroll-snap-align: start;
  .leaflet-map {
    height: calc(100vh - 6.8rem);
    @media (min-width: $md) {
      height: calc(100vh - 3.6rem);
    }
  }
}
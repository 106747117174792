.trail-details {
  margin: 0;
  padding: 0 1%;
  background-color: rgba($color-orange, .25);
  justify-content: space-around;
  border: 0;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: .5rem;

    @media (max-width: $lg) {
      flex-direction: column;
      min-width: 25%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 42px;
      max-width: 100%;
    }
  }
  small {
    // color: $color-black--medium;
    font-weight: normal;
  }
  dt {
    align-items: center;
  }
  .c-icon {
    width: 2rem;
    height: 2rem;
    fill: $color-black--light;
    &.rotate-90 {
      transform: rotate(90deg);
    }
  }
  dd {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: center;
    // margin-left: .5rem;
  }
  .diffing {
    cursor: pointer;
    background-color: rgba($color-orange, .3);
    transition: all $transition-normal;
  }
  .diffing:hover {
    background-color: rgba($color-orange, .95);
    dd::before {
      color: $color-black--light;
    }
  }
  .diffing div dd:not(:first-of-type) {
    display: none;
  }
  .diffing.isOn div dd {
    display: block;
  }
  .diffing.isOn div dd:first-of-type {
    display: none;
  }
  svg {
    opacity: .85;
    margin: 0
  }
  svg.faded {
    opacity: .25;
    filter: grayscale(1);
  }
  svg.positive {
    fill: $color-green;
    opacity: 1;
    filter: none;
  }
  svg.negative {
    fill: $color-accent;
    opacity: 1;
    filter: none;
  }
  svg.mark {
    position: absolute;
    width: 1rem;
    height: 1rem;
    margin-left: .5rem;
  }
  svg.ribbon {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    margin-top: -1rem;
  }
  dd[data-level] {
    font-family: system-ui;
  }
  // @media (max-width: $lg) {
    dd[data-title] {
      flex-direction: column;
      // flex-wrap: wrap;
      // justify-content: center;
      &::before {
        content: attr(data-title);
        color: $color-gray--dark;
        white-space: nowrap;
        display: block;
        font-weight: normal;
        font-size: .9rem;
        line-height: 1.1rem;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        transition: all $transition-normal;
      }
    }
  // }
  .tooltip {
    flex: 0;
    // margin: 0 .25rem;
    flex-direction: row;

    dd {
      @extend .sr-only;
    }
    @media (max-width: $lg) {
      flex: 1;
      justify-content: flex-start;

      dd.sr-only {
        &::before {
          content: attr(data-title);
          color: $color-gray--dark;
          white-space: nowrap;
          display: block;
        }
        text-align: left;
        font-weight: normal;
        margin: 0;
        width: auto;
        height: auto;
        position: relative;
        font-size: .9rem;
        line-height: 1.1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

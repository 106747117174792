.o-topbar {
  background-color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  // position: sticky;
  // top: 0;
  // z-index: 5;
  h2 {
    line-height: 2.5rem;
    margin: 0 auto 0 0;
    order: 1;
    min-width: calc(100% - 10rem);
  }
  button {
    border: 0;
    padding: .5rem 1rem;
    background-color: $color-white--medium;
    margin: 0 .25rem 0 0;
    height: 2.5rem;
    line-height: 1.75rem;
    cursor: pointer;
    transition: all 0.3s;
    // order: 0;
    color: rgba(darken(royalblue, 25%), .75);
    &:hover, &:focus {
      background-color: $color-white--dark;
    }
  }
  .field {
    display: flex;
    align-items: center;
    margin-right: 3.25rem;
    order: 2;
  }
  label {
    padding: .5rem 1rem;
    background-color: $color-white--medium;
    height: 2.5rem;
    line-height: 1.75rem;
    color: rgba(darken(royalblue, 25%), .75);
    cursor: pointer;
    &:hover, &:focus {
      background-color: $color-white--dark;
    }
    &.active {
      background-color: rgba(royalblue, .95);
      color: white;
    }
  }
  input[type=search] {
    padding: 0;
    height: 2.5rem;
    border: 0;
    background-color: $color-white--medium;
    margin: 0;
    width: 0;
    transition: all 0.3s;
    position: absolute;
    left: calc(100% - 3.25rem);
    &:focus {
      width: calc(100% - 6.5rem);
      z-index: 1;
      padding: .5rem;
      left: 3.25rem;
    }
  }
  details {
    order: 3;
  }
  details[open] summary {
    .c-icon { transform: rotate(90deg); }
  }
  .c-icon {
    transition: all 0.3s;
  }
  summary {
    position: absolute;
    padding: 0 1rem;
    right: 0;
    top: 0;
    height: 2.5rem;
    line-height: 2.5rem;
    color: rgba(darken(royalblue, 25%), .75);
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: $color-white--medium;
    transition: background-color 0.3s;
    &:hover, &:focus {
      background-color: $color-white--dark;
    }
    &.active {
      background-color: rgba(royalblue, .95);
      color: white;
    }
  }
  .c-tag-cloud {
    margin-top: .5rem;
    margin-bottom: -.25rem;
  }
  ::marker { display: none; }
  ::-webkit-details-marker { display: none; }

}
// Tag
//
// CSS-only tabs implementation. Use the `.c-tags` class to group tags.
//
// Markup:
// <span class="c-tag "></span>
//
// c-tag--large            - Use this class for larger tags.
//
// Styleguide: Components.Tag

.c-tags {
  margin-left: 1.3rem;
  position: relative;
  // font-size: 0;
  &:before {
    content: '#';
    color: rgba(white, .95);
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    left: -1.15rem;
    padding: .1rem;
  }
  a {
    border: 0;
    display: inline-block;
    &:not(.active):hover .c-tag {
      background-color: $color-white--dark;
    }
  }
}

.c-tag {
  display: inline-block;
  transition: all $transition-normal;
  font-size: .9rem;
  line-height: 1.5;
  background-color: rgba(white, .99);
  margin: 0 .1rem .3rem;
  padding: .25rem .5rem;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px $color-gray--light;

  &--large {
    @extend .c-tag;
    font-size: 1rem;
  }
  &--primary {
    @extend .c-tag;
    background-color: $color-pink;
    color: white;
  }
}

a.active .c-tag {
  background-color: rgba(royalblue, .95);
  color: $color-white--light;
}

.c-tag-cloud {
  text-align: left;
  .c-tags { margin-left: 0; }
  .c-tags:before { content: ''; }
  .c-tag { padding: .5rem; }
}

@keyframes scroll1 {
  from {transform: translateX(100vw);}
  to {transform: translateX(calc(-100% - 1rem));}
}
@keyframes scroll2 {
  from {transform: translateX(calc(-100% - 1rem));}
  to {transform: translateX(100vw);}
}

.c-slider {
  overflow: auto;
  width: 100%;
  padding: 1rem;
  &.scroll {
    overflow: hidden;
    .wrapper {
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 90s;
      &:hover, &:focus { animation-play-state: paused; }
    }
  }
  .wrapper {
    margin: auto;
    display: flex;
  }
  a {
    border-bottom: 0;
    margin: .5rem;
    flex-shrink: 0;
    display: flex;
    height: calc(50vh - 3rem);
    min-height: 240px;
  }
  figure {
    margin: 0;
    box-shadow: 0 0 1px 1px $color-gray--light;
    display: flex;
    position: relative;
  }
  picture {
    // max-height: 320px;
    min-width: 240px;
    max-width: 320px;
    overflow: hidden;
    @media (min-width: $xl) {
      max-width: 640px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .4s all;
  }
  figcaption {
    background-color: rgba(white, .75);
    text-align: center;
    padding: .25rem .5rem;
    font-size: 1.1rem;
    position: absolute;
    transition: .4s all;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.slider1 {
    background-color: rgba(white, .75);
    .wrapper {
      animation-name: scroll1;
    }
    a {
      // filter: sepia(.5);
      &:hover, &:focus {
        filter: sepia(.5);
      }
    }
  }
  &.slider2 {
    background-color: rgba(#cebcc2, .65);
    .wrapper {
      animation-name: scroll2;
    }
    a {
      // filter: grayscale(.5);
      &:hover, &:focus {
        img {
          transform: scale(1.15) rotate(-2deg);
        }
        figcaption {
          background-color: white;
        }
      }
    }

  }
}

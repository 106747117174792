// Page Header
//
// Markup:
// <header class="p-header">
//   <a href="#" class="p-header__logo">
//     <img src="../../assets/images/ron-logo-inv.svg" alt="Brand Logo">
//   </a>
//   <label for="toggle-menu" class="p-header__toggle-menu">≡</label>
//   <input type="checkbox" id="toggle-menu" aria-label="Toggle menu">
//   <nav class="p-header__nav">
//   <a class="p-header__navitem is-active" href="#">Dropdown</a>
//   <div class="p-header__submenu">
//     <a class="p-header__subitem--cat1" href="#">
//       <svg class="p-header__subicon c-icon" aria-hidden="true"><use xlink:href="#icon-sample"></use></svg>
//       <span>Submenu item 1</span>
//     </a>
//     <a class="p-header__subitem--cat2 is-active" href="#">
//       <svg class="p-header__subicon c-icon" aria-hidden="true"><use xlink:href="#icon-sample"></use></svg>
//       <span>Submenu item 2</span>
//     </a>
//     <a class="p-header__subitem--cat3" href="#">
//       <svg class="p-header__subicon c-icon" aria-hidden="true"><use xlink:href="#icon-sample"></use></svg>
//       <span>Submenu item 3</span>
//     </a>
//   </div>
//   <a class="p-header__navitem" href="#">Link</a>
//   <a class="p-header__navitem p-header__navitem--image" href="#">
//     <img class="p-header__navimage" src="../../assets/images/flags/el.gif" alt="Ελληνικά">
//   </a>
//   </nav>
// </header>
//
// Styleguide Patterns.Header

.p-header {
  // border-bottom: .1rem solid $color-black--dark;
  scroll-snap-align: start;
  height: 3.75rem;
  background-color: rgba(black, .85);
  @media (max-width: $md) {
    position: sticky;
    top: 0;
    z-index: 1101;
  }

  a {
    border-bottom: 0;
    color: $color-white--dark;
    &:hover {
      color: $color-white--light;
    }
    &.is-active {
      background-color: $color-black--dark;
    }
    .c-icon.is-active { fill: royalblue; }
  }
  input[type=checkbox] {
    display: none;
    &:checked ~ nav {
      display: block;
      position: absolute;
      top: 3.75rem;
      padding-bottom: .5rem;
    }
  }
}

.p-header__container {
  max-width: $lg;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  height: 3.75rem;
  display: flex;
}

.p-header__slogan {
  font-style: italic;
  color: bisque;
  line-height: 1.35;
  @media (max-width: $md) {
    display: none;
  }
}

// .p-header__logo {
//   position: absolute;
// }

.p-header__logo svg {
  height: 3.5rem;
  width: 7.875rem;
  margin: .1rem 1rem -.2rem;
}

.p-header__logo svg {
  fill: royalblue;
  // stroke-color: white;
  // stroke-width: 2px;
}

.p-header__toggle-menu {
  font-size: 2.5rem;
  text-align: center;
  padding: .5rem 1rem;
  line-height: 1;
  float: right;
  opacity: .95;
  display: none;
  height: 3.75rem;
  cursor: pointer;
  transition: all $transition-fast;
  user-select: none;
  &:hover {
    opacity: 1;
    color: $color-white--light;
  }
}

.p-header__nav {
  // background: $color-black--medium;
  display: inline-block;
  vertical-align: top;
  float: right;
  position: relative;
  z-index: 9;
  &.home > a:not(.p-header__navitem--image) {
    @media (min-width: $md) {
      display: none;
    }
  }
}

// @media (max-width: $md) {
//   float: none;
// }

.p-header__navitem {
  height: 3.75rem;
  line-height: 3.75rem;
  // vertical-align: middle;
  display: inline-block;
  padding: 0 .5rem;
  &--image {
    @extend .p-header__navitem;
    padding: 0 1rem;
    filter: grayscale(1);
    // transition: all $transition-fast;
    &:hover, &:focus {
      filter: none;
    }
  }
  .c-icon {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: text-bottom;
  }
}

.p-header__submenu {
  z-index: 5;
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all $transition-fast;
  background: $color-black--medium;
  display: grid;
  // min-width: 10rem;
}

.p-header__navitem:hover + .p-header__submenu,
.p-header__submenu:hover {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.p-header__subitem {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 95%;
  &:not(:last-of-type) {
    border-bottom: .05rem dashed $color-gray--dark;
  }
  &--cat1, &--cat2, &--cat3,
  &--web-services, &--app-services, &--social-services {
    @extend .p-header__subitem;
    border-left: 4px solid transparent;
  }
  &--cat1, &--web-services { border-color: $color-accent; }
  &--cat2, &--app-services { border-color: $color-green; }
  &--cat3, &--social-services { border-color: $color-blue; }
}

.p-header__subicon {
  // vertical-align: middle;
  // width: .9rem;
  // height: .9rem;
  & + span { vertical-align: text-bottom; }
}

.p-header__languages {
  margin: 0 .5rem;
  display: inline-flex;
}

.p-header__navimage {
  height: 1rem;
  width: 1.5rem;
  margin: 1.35rem 0;
}

@media screen and (max-width: $md) {
  .p-header__container {
    display: block;
  }
  .p-header__nav {
    display: none;
    width: 100%;
    background-color: rgba(black, .85);
    float: none;
  }

  .p-header__slogan {
    font-size: .85rem;
  }

  .p-header__toggle-menu {
    display: inline-block;
  }

  .p-header__navitem {
    display: block;
    padding-left: 1rem;
  }

  .p-header__submenu {
    display: none;
  }

  .p-header__languages {
    position: absolute;
    right: 3.75rem;
    top: -3.75rem;
  }
}

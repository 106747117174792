.o-header,
.o-footer {
  padding: 1rem;
}

.o-container {
  display: flex;
  // margin: 1rem 0;
  max-width: $xl;
  margin: auto;
  margin-bottom: 2rem;
  &.margin {
    margin: 1rem auto;
  }

  .o-sidebar {
    flex-basis: 35%;
    padding: 1rem 1rem 0 0;
    order: 2;
    // &--flipped {
    //   order: 2;
    // }
    &.padding-top {
      padding-top: 2rem;
    }
  }

  .o-content {
    scroll-snap-align: start;
    flex-basis: 65%;
    max-width: $md;
    margin: 0 auto auto;
    padding: 1rem;
    // order: 0;
    position: relative;
    &.no-padding {
      padding: 0;
    }
    img {
      max-width: 100%
    }
    ul {
      padding-left: 2rem;
    }
    li > a {
      // text-overflow: ellipsis;
      max-width: calc(100vw - 3rem);
      overflow: hidden;
      white-space: nowrap;
      display: inline-flex;
      vertical-align: middle;
      // margin-bottom: .5rem;
    }
  }
}

@media (max-width: $md) {
  .o-container {
    flex-wrap: wrap;
    .o-sidebar, .o-content {
      flex-basis: 100%;
    }
    // .o-content {
    //   order: 1;
    // }
    .o-sidebar {
      padding-left: 1rem;
    }
  }
}

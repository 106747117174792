.s-bazaars {
  margin-top: 2rem;
}

.s-bazaar {
  .o-topbar {
    small {
      display: inline-block;
      margin-left: .5rem;
    }
    h2 {
      line-height: 1.25;
    }
  }
  .s-day {
    scroll-snap-align: start;
    h3 {
      font-weight: bold;
      margin-bottom: .5rem;

      a {
        display: block;
      }
    }
    h4 {
      font-weight: bold;
      margin: 0;
      line-height: 1.25;
    }
    em {
      font-size: 85%;
      line-height: 1.25
    }
}
  .c-list .c-list--item .wrapper {
    justify-content: space-between;
    & > div {
      display: flex;
      gap: .5rem;
      align-items: center;
    }
  }
  .c-list .c-list--item .title {
    margin-bottom: 0;
  }
  .c-icon--food {
    fill: green;
  }
  .c-icon--clothes {
    fill: purple;
  }
}
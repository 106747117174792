// Fonts
//
// All fonts used in the website
//
// Markup:
// <p class="" style="font-size: 200%;">A sample text / Ένα δείγμα κειμένου</p>
//
// .u-default-font   - -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Segoe UI Symbol"
// .u-primary-font   - opensans, sans-serif;
// .u-secondary-font - roboto_slab, serif;
//
// Styleguide Settings.Fonts
$default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Segoe UI Symbol";
$primary-font-family: opensans, sans-serif;
$secondary-font-family: roboto_slab, serif;

// Colors
//
// All colors of our brand
//
// $color-black--trans     - rgba(0,0,0,.9); Transparent Black
// $color-black--dark      - #111; Dark Black
// $color-black--medium    - #23262A; Medium Black
// $color-black--light     - #333; Light Black
// $color-gray--dark       - #555; Dark Gray
// $color-gray--medium     - #888; Medium Gray
// $color-gray--light      - #aaa; Light Gray
// $color-white--dark      - #dfdfdf; Dark White
// $color-white--medium    - #eee; Medium White
// $color-white--light     - #fff; Light White
// $color-primary          - #86da24; Primary color
// $color-accent           - #a52a2a; Accent color
// $color-green            - #393; Green color
// $color-blue             - #339; Blue color
//
// Styleguide Settings.Colors
$color-black--trans: rgba(0,0,0,.9);
$color-black--dark: #111;
$color-black--medium: #23262A;
$color-black--light: #333;
$color-gray--dark: #555;
$color-gray--medium: #888;
$color-gray--light: #aaa;
$color-white--dark: #f2f2f2;
$color-white--medium: #f8f8f8;
$color-white--light: #fff;
$color-primary: #90fb04; //#86da24;
$color-accent: #a52a2a;
$color-green: #393;
$color-blue: #339;
$color-pink: #634;
$color-orange: #ef870e;
$color-highlight: aliceblue;

// Backgrounds
//
// The different backgrounds used in the sections
//
// Markup:
// <div style="width: 100%; height: 15vw; border: 1px dashed black" class=""></div>
//
// .u-background-black - Simple black background
// .u-background-dark - Simple color dark background
// .u-background-light - Simple color light background
// .u-background-dark--textured - Dark background with background image
// .u-background-light--textured - Light background with background image
// .u-background-primary - Background with image in primary color
// .u-background-primary--grayscale - Background with image in grayscale (Light)
// .u-background-primary--inverted - Background with image in  grayscale (Dark)
// .u-background-primary--fixed - Background with fixed attachment and altered color
//
// Styleguide Settings.Backgrounds
$background-banner-image: ''; //url(../images/banner-background.jpg);
$background-section-image: ''; //url(../images/section-background.png);

// Transitions
//
// CSS transitions for elements
//
// Slow - 1s
// Normal - .5s
// Fast - .25s
//
// Styleguide Settings.Transitions
$transition-slow: 1s;
$transition-normal: .5s;
$transition-fast: .25s;

// Sizes & Rhythm
//
// Font sizes and line heights<br><br>
//
// Base font size is <b>1rem</b><br>
// Base line-height is <b>1.5rem</b><br>
// Levels are <b>small</b> [0.75rem], <b>normal</b> [1rem], <b>large</b> [1.333rem], <b>xlarge</b> [1.777rem]
//
// Markup:
// <p class="u-text-small">Small text</p>
// <p class="u-text-normal">Normal text</p>
// <p class="u-text-large">Large text</p>
// <p class="u-text-xlarge">Extra large text</p>
//
// Styleguide Settings.Sizes

$base-font-size: 1rem;
$base-line-height: $base-font-size * 1.5;

$type-scale: (
  small: 0.75rem,
  normal: 1rem,
  large: 1.333rem,
  xlarge: 1.777rem
);

$line-heights: (
  small: $base-line-height * 0.75,
  normal: $base-line-height,
  large: $base-line-height * 1.333,
  xlarge: $base-line-height * 1.777
);

// Breakpoints
//
// Screen sizes in rem unit (16px default)<br><br>
//
// $xs - Extra small - <b>20rem</b> [320px]<br>
// $sm - Small - <b>30rem</b>[480px]<br>
// $md - Medium - <b>50rem</b>[800px]<br>
// $lg - Large - <b>70rem</b>[1120px]<br>
// $xl - Extra Large - <b>100rem</b>[1600px]<br>
//
// Styleguide Settings.Breakpoints
$xs: 20rem;
$sm: 30rem;
$md: 50rem;
$tb: 60rem;
$lg: 70rem;
$xl: 100rem;

// Logos
//
// Brand logos and icons
//
// Markup:
// <img src="../assets/images/ron-logo-inv.svg" alt="Ron Logo"/>
// <img src="../favicon.ico" alt="Ron Favicon"/>
//
// Styleguide Settings.Logos

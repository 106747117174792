.c-hero {
  height: calc(100vh - 3.75rem);

  img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
    margin-top: -3.75rem;
    object-fit: cover;
    // object-position: top;
  }
  .wrapper {
    position: absolute;
    // margin: auto;
    max-width: 80rem;
    bottom: 1rem;
    left: 1rem;
    // height: calc(100vh - 3.75rem);
  }
  h2 {
    background-color: rgba(royalblue, .95);
    color: $color-white--light;
    margin: 0;
    // position: absolute;
    // left: 1rem;
    // bottom: 2rem;
    padding: .5rem 1rem;
    min-width: auto;
    font-size: 2.5rem;
    line-height: 1.25;
    border-radius: .2rem;
    @media (max-width: $sm) {
      font-size: 8vw;
      bottom: 3rem;
    }
  }
  .c-tags {
    margin-top: 1rem;
  }
  // .c-tags, .subtitle {
  //   position: absolute;
  //   left: 1rem;
  //   bottom: 1rem;
  // }

  .subtitle {
    background-color: rgba(royalblue, .5);
    color: white;
    padding: .5rem 1rem;
    bottom: 1.5rem;
  }
}

.c-list {
  .no-results {
    text-align: center;
    color: royalblue;
    font-size: 1.25rem;
    font-style: italic;
  }
  .c-list--item {
    display: flex;
    flex-direction: column;
    background-color: $color-white--medium;
    margin: 0 0 .5rem;
    transition: all $transition-normal;
    border: 1px solid $color-gray--light;
    // box-shadow: 0 0 2px 1px $color-gray--light;
    .wrapper {
      position: relative;
      display: flex;
      padding: .5rem;
      align-items: center;
      // transition: all $transition-fast;
    }
    &.collapsed {
      // height: 0;
      // width: 0;
      // padding: 0;
      // overflow: hidden;
      // margin: 0;
      // border: 0;
      display: none;
      details { display: none; }
    }
    &.active {
      background-color: rgba(royalblue, .15);
    }
    picture {
      display: flex;
      flex-shrink: 0;
    }
    img {
      // width: 150px;
      // height: 112px;
      width: 100px;
      height: 75px;
      max-height: 113px;
      object-fit: cover;
      margin-right: .75rem;
      &.thumbnail {
        box-shadow: 0 0 1px 1px $color-white--dark;
      }
    }
    @media (max-width: $md) {
      .wrapper {
        flex-direction: column;
      }
      img {
        height: 100px;
        border-radius: 100%;
        margin: 1rem;
      }
    }
    .title {
      // transition: all $transition-normal;
      font-size: 1.25rem;
      margin-bottom: .8rem;
      display: flex;
      font-weight: normal;
      .c-tag--primary {
        // position: absolute;
        // margin-left: .75rem;
        margin: 0 0 0 .5rem;
        // z-index: 1;
        // right: .4rem;
        height: 1.5rem;
        padding: 1px .5rem;

      }
    }
    .c-tags {
      margin-right: 2.5rem;
    }
    .c-tags:before {
      color: rgba(black, .5);
    }
    .c-tag {
      margin: 0 .1rem .35rem;
    }

    details[open] summary ~ * {
      animation: sweep .5s ease-in-out;
    }

    @keyframes sweep {
      0%    { opacity: 0; margin-right: -.5rem; }
      100%  { opacity: 1; margin-right: .25rem; }
    }

    details {
      &[open] {
        padding: .5rem .75rem;
        border-top: 1px dashed $color-gray--medium;
        // summary {
        //   margin-top: -2.6rem;
        // }
      }
      & > a {
        float: right;
        // color: royalblue;
        display: inline-block;
        margin: 0 .25rem;
        border-bottom-color: royalblue;
      }
      & > p {
        margin: 0;
      }

    }

    summary {
      position: absolute;
      right: 1.5rem;
      margin-top: -3.5rem;
      font-size: 3rem;
      color: rgba(darken(royalblue, 25%), .75);
      cursor: pointer;
      transition: color $transition-normal;
      padding: .75rem;
      width: 3rem;
      height: 3rem;
      &:hover, &:focus {
        color: black;
      }
    }
  }

  &.sidebar {
    img {
      width: 72px;
      height: 54px;
      object-fit: cover;
      margin: 0;
    }

    .c-list--item {
      align-items: center;
      flex-direction: row;
      // box-shadow: 0 0 1px 1px $color-gray--light;
      .c-list--item-details {
        display: flex;
        padding: 0 .5rem;
        font-size: .95rem;
        border-bottom: 0;
        line-height: 1.25;
        align-items: center;
        width: 100%;
        height: 54px;
        &:hover {
          background: $color-white--dark;
        }
      }
    }
  }
}